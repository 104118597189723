<template>
  <div>
    <v-card :elevation="1" class="v-sheet theme--light br-0">
      <v-card-title>
        <v-row class="col-md-12">
          <v-col cols="12">
            <v-btn
              class="float-right"
              outlined
              color="black"
              :to="
                currentRoles.includes('SCO$ADMINISTRADOR')
                  ? `/proyectos/cns/administracion`
                  : `/proyectos/cns/externo/registro-informacion`
              "
            >
              <v-icon>mdi-arrow-left</v-icon>
              Regresar
            </v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            Enmiendas de fechas
            <v-skeleton-loader
              v-if="skeletonLoading"
              min-height="50px"
              type="list-item"
            ></v-skeleton-loader>
            <v-subheader
              class="pl-0 font-weight-black text-h5 text--blue-grey text--lighten-4"
              v-if="!skeletonLoading"
            >
              {{ codigoProyecto }}
            </v-subheader>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-btn
              v-if="proyectoEncontrado"
              color="light-blue-502"
              dark
              class="mb-2 float-right white--text"
              @click="modalNuevo"
            >
              <v-icon left>mdi-plus</v-icon>
              Nueva enmienda
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="col-md-12 m-0 p-0">
          <v-divider></v-divider>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-skeleton-loader
              v-if="skeletonLoading"
              min-height="100px"
              type="list-item-two-line"
            ></v-skeleton-loader>

            <v-list-item two-line v-if="!skeletonLoading && proyectoEncontrado">
              <v-list-item-content>
                <v-list-item-title class="text-subtitle-1 font-weight-bold"
                  >Fecha de fase de ejecución actual</v-list-item-title
                >
                <v-list-item-subtitle class="text-h6">{{
                  fechaFaseEjecucionActual
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item two-line v-if="!skeletonLoading && proyectoEncontrado">
              <v-list-item-content>
                <v-list-item-title class="text-subtitle-1 font-weight-bold"
                  >Fecha de finalización actual</v-list-item-title
                >
                <v-list-item-subtitle class="text-h6">{{
                  fechaFinalizacionActual
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>

        <v-row v-if="!skeletonLoading && !proyectoEncontrado">
          <v-col cols="12" sm="12" md="12">
            <v-alert
              text
              dense
              color="deep-orange lighten-1"
              icon="mdi-exclamation"
              border="left"
            >
              No se encontró información del proyecto especificado, por favor,
              inténtelo nuevamente. <br />
              Si el problema persiste, contacte al administrador del sistema.
            </v-alert>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pl-0 pr-0">
        <v-data-table
          class="elevation-0"
          :headers="headers"
          :items="enmiendas"
          :loading="tableLoading"
          :search="filtro"
          no-data-text="No se encontraron registros"
          no-results-text="No se encontraron registros con el filtro especificado"
          loading-text="Cargando..."
          :header-props="{
            sortByText: 'Ordenar por'
          }"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-page-first',
            lastIcon: 'mdi-page-last',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right',
            'items-per-page-text': 'Registros por página',
            pageText: '{0}-{1} de {2}'
          }"
        >
          <template v-slot:top>
            <!-- v-container, v-col and v-row are just for decoration purposes. -->
            <v-container fluid>
              <v-row>
                <v-col cols="6" md="6" sm="12" xs="12">
                  <v-text-field
                    autocomplete="off"
                    dense
                    filled
                    color="blue-grey lighten-2"
                    v-model="filtro"
                    label="Número de enmienda, descripción..."
                    maxlength="200"
                  >
                    <v-icon slot="append" color="blue-grey darken-2"
                      >mdi-magnify</v-icon
                    >
                  </v-text-field>
                </v-col>

                <v-col cols="6" md="6" sm="12" xs="12">
                  <v-select
                    v-model="estadoSeleccionado"
                    :items="estados"
                    :loading="false"
                    dense
                    filled
                    label="Estado"
                    :no-data-text="'No existen estados registrados'"
                    menu-props="auto"
                    return-object
                  ></v-select>
                </v-col>

                <v-col cols="2" md="2" sm="12" xs="6" class="d-none">
                  <v-btn
                    class="ma-0 white--text d-none"
                    medium
                    color="light-blue darken-2"
                  >
                    <v-icon left>mdi-magnify</v-icon> Buscar
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.orden }}</td>
              <td>
                {{ item.codigo }}
                <v-chip
                  class="ma-2 font-weight-medium"
                  label
                  small
                  :color="
                    item.tipoProyecto === 'SIGEACI'
                      ? 'blue-grey lighten-4'
                      : 'blue lighten-4'
                  "
                  :text-color="
                    item.tipoProyecto === 'SIGEACI'
                      ? 'blue-grey lighten-1'
                      : 'blue lighten-1'
                  "
                >
                  {{ item.tipoProyecto }}
                </v-chip>
              </td>
              <td>{{ item.fechaCreacion | formatDate }}</td>
              <td>{{ item.fechaFaseEjecucionNueva }}</td>
              <td>{{ item.fechaFinalizacionNueva }}</td>
              <td>
                {{ item.observaciones }}
              </td>
              <td>
                <v-chip
                  class="ma-2 font-weight-medium"
                  label
                  :color="
                    item.estadosId === 1
                      ? 'blue-grey lighten-4'
                      : item.estadosId === 2
                      ? 'red lighten-4'
                      : item.estadosId === 4
                      ? 'cyan lighten-4'
                      : item.estadosId === 6
                      ? 'cyan lighten-4'
                      : item.estadosId === 7
                      ? 'blue lighten-4'
                      : 'pink lighten-4'
                  "
                  :text-color="
                    item.estadosId === 1
                      ? 'blue-grey lighten-1'
                      : item.estadosId === 2
                      ? 'red lighten-1'
                      : item.estadosId === 4
                      ? 'cyan darken-1'
                      : item.estadosId === 6
                      ? 'cyan darken-1'
                      : item.estadosId === 7
                      ? 'blue lighten-1'
                      : 'pink darken-1'
                  "
                  small
                >
                  {{ item.estadosId === 1 ? `En registro` : item.estado }}
                </v-chip>
              </td>
              <td>
                <v-btn
                  v-if="item.tipoProyecto === `SICOOPERA`"
                  class="
                    ma-2
                    btn-bg-light
                    blue-grey--text
                    lighten-2--text
                    font-weight-medium
                    text-capitalize
                  "
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="obtenerDatosItem(item.id)"
                >
                  <v-icon left>{{ item.estadosId === 1 || item.estadosId === 2 ? `mdi-file-document-outline` :`mdi-eye` }}</v-icon>
                  {{
                    item.estadosId === 1 || item.estadosId === 2
                      ? `Actualizar enmienda`
                      : `Visualizar enmienda`
                  }}
                </v-btn>

                <v-btn
                  v-if="item.tipoProyecto === `SIGEACI`"
                  class="
                  ma-2
                  btn-bg-light
                  blue-grey--text
                  lighten-2--text
                  font-weight-medium
                  text-capitalize
                "
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="visualizarEnmiendaSigeaci(item.id)"
                >
                  <v-icon left>mdi-eye</v-icon> Visualizar enmienda
                </v-btn>

                <v-btn
                  v-if="
                    (item.estadosId === 1 || item.estadosId === 4) &&
                      (currentRoles.includes('SCO$ADMINISTRADOR') ||
                        currentRoles.includes('SCO$SUBADMINISTRADOR'))
                  "
                  class="
                    ma-2
                    btn-bg-light  
                    blue-grey--text
                    lighten-2--text
                    font-weight-medium
                    text-capitalize
                  "
                  @click="mostrarModalInactivar(item.id, 2)"
                  target="_blank"
                  small
                  depressed
                  color="blue-grey lighten-5"
                >
                  <v-icon left>mdi-tab-remove</v-icon>
                  Inactivar enmienda
                </v-btn>

                <!-- <v-btn
                  v-if="
                    item.estadosId === 2 &&
                      (currentRoles.includes('SCO$ADMINISTRADOR') ||
                        currentRoles.includes('SCO$SUBADMINISTRADOR'))
                  "
                  class="
                    ma-2
                    btn-bg-light  
                    blue-grey--text
                    lighten-2--text
                    font-weight-medium
                    text-capitalize
                  "
                  @click="mostrarModalInactivar(item.id, 4)"
                  target="_blank"
                  small
                  depressed
                  color="blue-grey lighten-5"
                >
                  <v-icon left>mdi-file-check-outline</v-icon>
                  Activar enmienda
                </v-btn> -->
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <!-- <template v-slot:html>
            {{ code4.html }}
          </template>
          <template v-slot:js>
            {{ code4.js }}
          </template> -->
    </v-card>

    <!--begin:: modal registro-->
    <v-dialog
      v-model="dialogRegistro"
      max-width="1024px"
      transition="scroll-y-transition"
      persistent
      scrollable
    >
      <v-card tile>
        <v-card-title>
          {{
            accion === 1
              ? `Registrar enmienda de fechas`
              : `Visualizar enmienda`
          }}
          | {{ codigoProyecto }}

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnRegistroLoading"
            @click="
              dialogRegistro = false;
              resetForm();
            "
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="d-none"></v-divider>

        <v-card-text class="pl-8 pr-8 pt-5">
          <v-card outlined>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="12" sm="12" class="pt-0  pb-0 mb-0">
                  <v-subheader class="text-h6 black--text">
                    Información actual
                  </v-subheader>
                  <v-divider class="pt-0"></v-divider>
                </v-col>

                <v-col cols="12" md="6" sm="12" class="pt-0  pb-0 mb-0">
                  <v-text-field
                    autocomplete="off"
                    class="required"
                    dense
                    :disabled="true"
                    filled
                    color="blue-grey lighten-2"
                    v-model="datosFechasActuales.fechaFaseEjecucion"
                    label="Fecha fase de ejecución"
                    maxlength="10"
                    v-mask="'##/##/####'"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="6" sm="12" class="pt-0  pb-0 mb-0">
                  <v-text-field
                    autocomplete="off"
                    class="required"
                    dense
                    :disabled="true"
                    filled
                    color="blue-grey lighten-2"
                    v-model="datosFechasActuales.fechaFinalizacion"
                    label="Fecha de finalización"
                    maxlength="10"
                    v-mask="'##/##/####'"
                  >
                  </v-text-field>
                </v-col>

                <!--  <v-col cols="12" md="12" sm="12" class="pt-0  pb-0 mb-0">
                              <v-textarea
                                  autocomplete="off"
                                  class="required"
                                  dense
                                  filled
                                  color="blue-grey lighten-2"
                                  v-model="datosItem.observaciones"
                                  label="Observaciones"
                                  :rules="[
                                      required('observaciones'),
                                      minLength('observaciones', 5),
                                      maxLength('observaciones',2000)
                                      
                                  ]"
                                  maxlength="2000"
                                  
                                  >
                              </v-textarea>
                          </v-col> -->
              </v-row>
            </v-card-text>
          </v-card>

          <v-card outlined class="mt-4">
            <v-card-text>
              <v-form
                ref="form"
                v-on:submit.prevent="registrarItem"
                v-model="validForm"
              >
                <v-row>
                  <v-col cols="12" md="12" sm="12" class="pt-0 pb-0 mb-0">
                    <v-subheader class="text-h6 black--text">
                      Modificación de plazos &nbsp;
                      <small> Tiempo del proyecto</small>
                    </v-subheader>
                    <v-divider class="pt-0"></v-divider>
                  </v-col>

                  <v-col cols="12" md="6" sm="12" class="pt-0  pb-0 mb-0">
                    <v-text-field
                      autocomplete="off"
                      class="required"
                      dense
                      :disabled="deshabilitarCampos"
                      filled
                      color="blue-grey lighten-2"
                      v-model="fechaFaseEjecucionNueva"
                      label="Fecha fase de ejecución"
                      :rules="[
                        required('Fecha fase de ejecución'),
                        minLength('Fecha fase de ejecución', 10),
                        maxLength('Fecha fase de ejecución', 10),
                        dateFormat('Fecha fase de ejecución', 10)
                      ]"
                      maxlength="10"
                      v-mask="'##/##/####'"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="6" sm="12" class="pt-0  pb-0 mb-0">
                    <v-text-field
                      autocomplete="off"
                      class="required"
                      dense
                      :disabled="deshabilitarCampos"
                      filled
                      color="blue-grey lighten-2"
                      v-model="fechaFinalizacionNueva"
                      label="Fecha de finalización"
                      :rules="[
                        required('Fecha de finalización'),
                        minLength('Fecha de finalización', 10),
                        maxLength('Fecha de finalización', 10),
                        dateFormat('Fecha de finalización', 10)
                      ]"
                      maxlength="10"
                      v-mask="'##/##/####'"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" class="pb-0">
                    <v-spacer></v-spacer>
                    <v-btn
                      v-if="!deshabilitarCampos"
                      color="light-blue-502"
                      class="white--text mb-2 float-right mt-2"
                      type="submit"
                      :elevation="0"
                      :disabled="!validForm"
                      :loading="btnRegistroLoading"
                    >
                      Guardar enmienda
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>

          <v-card outlined class="mt-4" v-if="enmiendaRegistrada">
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <span class="card-label text-h6 font-weight-bolder text-dark">
                    Confirmar enmienda </span
                  ><br />
                  <span
                    class="gray--text mt-3 font-weight-bold text-subtitle-1"
                  >
                    Debe cargar el documento de respaldo para que la enmienda
                    sea aplicada
                  </span>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-divider class="mt-0 pt-2 pb-2"></v-divider>
                </v-col>
              </v-row>

              <v-form
                v-if="!enmiendaValidada"
                ref="formDatosFinancieros"
                v-on:submit.prevent="cargarArchivo"
                v-model="validFormValidarEnmienda"
              >
                <v-row>

                  <v-col cols="12" md="12" sm="12" class="pt-0  pb-0 mb-0">
                    <v-textarea
                      autocomplete="off"
                      class="required"
                      dense
                      :disabled="enmiendaValidada"
                      filled
                      color="blue-grey lighten-2"
                      v-model="datosItem.observaciones"
                      label="Observaciones"
                      :rules="[
                        required('observaciones'),
                        minLength('observaciones', 5),
                        maxLength('observaciones', 2000)
                      ]"
                      maxlength="2000"
                    >
                    </v-textarea>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                    sm="12"
                    class="pt-0 pb-0"
                    v-if="!enmiendaValidada"
                  >
                    <template>
                      <v-file-input
                        id="respaldoFile"
                        dense
                        filled
                        placeholder="Seleccionar archivo .pdf"
                        label="Seleccione el documento de respaldo"
                        append-icon="mdi-file-pdf-outline"
                        prepend-icon=""
                        accept="application/pdf,image/jpeg"
                        @change="onFileChange"
                        :rules="[fileRequired('documento')]"
                        :show-size="1000"
                        ref="respaldoFile"
                        class="required"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip v-if="index < 2" small label color="primary">
                            {{ text }}
                          </v-chip>
                          <span
                            v-else-if="index === 2"
                            class="
                                          text-overline
                                          grey--text
                                          text--darken-3
                                          mx-2
                                      "
                          >
                            +{{ files.length - 2 }} archivo(s)
                          </span>
                        </template>
                      </v-file-input>
                    </template>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="12" class="pb-0">
                    <v-spacer></v-spacer>

                    <!-- <v-btn
                              color="grey lighten-5"
                              elevation="0"
                              class=" mt-2 float-right grey lighten-5"
                              @click="dialogRegistro = false; resetForm()"
                              :disabled="btnRegistroLoading"
                            >
                            Cerrar
                          </v-btn>
 -->

                    <v-btn
                      v-if="!enmiendaValidada"
                      color="light-blue-502"
                      class="white--text mb-2 float-right mt-2"
                      type="submit"
                      :elevation="0"
                      :disabled="!validFormValidarEnmienda"
                      :loading="btnConfirmacionLoading"
                    >
                      Confirmar enmienda
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>

          <v-card outlined class="mt-4" v-if="enmiendaValidada">
            <v-card-text>
              <v-row>
                <v-col cols="12" md="12" sm="12" class="text-center">
                  <v-icon large color="green darken-2">
                    mdi-check-all
                  </v-icon>
                </v-col>
                <v-col cols="12" md="12" sm="12" class="text-center">
                  <p class="text-h6">La enmienda ya se encuentra aplicada!</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                  sm="12"
                  class="pt-0 pb-0 text-center"
                  v-if="datosItem.pathDocumentoRespaldo"
                >
                  <v-btn
                    color="light-blue-502"
                    class="white--text mb-2 mt-2"
                    @click="descargarArchivo(datosItem.pathDocumentoRespaldo)"
                    :elevation="0"
                  >
                    <v-icon left>mdi-cloud-download</v-icon>
                    Descargar documento de respaldo
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--begin:: dialog inactivar/activar enmienda -->
    <v-dialog
      v-model="dialogActualizarEstado"
      width="500"
      transition="scroll-y-transition"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ textoTituloCambioEstado }}

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnCambioEstadoLoading"
            persistent
            transition="dialog-bottom-transition"
            @click="dialogActualizarEstado = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <p class="text-subtitle-1">
                {{ textoCambioEstado }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="dialogActualizarEstado = false"
            :disabled="btnCambioEstadoLoading"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="actualizarEstadoEnmienda()"
            :loading="btnCambioEstadoLoading"
          >
            Sí, continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--end: dialog-->

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
      transition="scroll-y-transition"
    ></DialogLoader>
    <!---->
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import DialogLoader from "@/view/content/DialogLoader";
import validations from "@/core/untils/validations.js";
import { CARGAR_ARCHIVO } from "@/core/services/store/cargaarchivo.module";
import { OBTENER_ITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";
import {
  OBTENER_ENMIENDAS_FECHAS_CNS,
  REGISTRAR_ENMIENDA_FECHAS_CNS,
  OBTENER_ENMIENDA_FECHAS_CNS,
  ACTUALIZAR_ESTADO_ENMIENDA_FECHA_CNS,
  CONFIRMAR_ENMIENDA_FECHAS_CNS
} from "@/core/services/store/proyectoscns/enmiendas/enmiendafechas.module";
import { OBTENER_DATOS_SECCIONES_PROYECTO } from "@/core/services/store/proyectoscns/proyectocns.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DownloadFile from "@/core/untils/downloadfile.js";
moment.locale("es");
Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
});

export default {
  name: "AdminEnmiendasComponentes",
  components: {
    SnackAlert,
    DialogLoader
  },
  data() {
    return {
      enmiendaRegistrada: false,
      validForm: false,
      validFormValidarEnmienda: false,
      validFormProyeccionSubDivision: false,
      btnProyeccionLoading: false,
      dialogRegistro: false,
      btnRegistroLoading: false,
      btnConfirmacionLoading: false,
      tableLoading: false,
      datosFechasActuales: {},
      skeletonLoading: false,
      filtro: "",
      enmiendas: [],
      parameters: [],
      proyectoId: "",
      codigoProyecto: "",
      items: [],
      datosValidacion: {},
      estados: [
        { value: null, text: "Todos" },
        { value: 1, text: "En registro" },
        { value: 2, text: "Inactiva" },
        { value: 4, text: "Confirmado" }
      ],
      enmiendaValidada: false,
      estadoSeleccionado: "",
      datosProyecto: {},
      fechaFaseEjecucionActual: "",
      fechaFinalizacionActual: "",
      fechaFaseEjecucionNueva: "",
      fechaFinalizacionNueva: "",
      dialogLoaderVisible: false,
      dialogLoaderText: "Obteniendo datos de la enmienda",
      proyectoEncontrado: false,
      datosItem: {
        proyectosCNSId: this.proyectoId,
        fechaFaseEjecucionNueva: "",
        pathDocumentoRespaldo: "",
        fechaFinalizacionNueva: "",
        observaciones: "",
        usuarioCreacion: "admin",
        estadosId: 4,
        fechaFaseEjecucionModificada: "",
        fechaFinalizacionModificada: ""
      },
      accion: 1,
      deshabilitarCampos: false,
      subDivisiones: [],
      subDivision: {},
      descripcionSubdivision: "",
      proyeccionesSubDivision: [],
      archivo: [],
      archivoCargado: false,
      textoTituloCambioEstado: "",
      textoCambioEstado: "",
      dialogActualizarEstado: false,
      idSeleccionado: 0,
      estadoActualId: 0,
      btnCambioEstadoLoading: false,
      enmiendaId: 0,
      ...validations
    };
  },

  methods: {
    filtroEstado(value) {
      // If this filter has no value we just skip the entire filter.
      if (!this.estadoSeleccionado.value) {
        return true;
      }
      // Check if the current loop value (The calories value)
      // equals to the selected value at the <v-select>.
      return value === this.estadoSeleccionado.value;
    },

    //Obtener la información de fechas
    async obtenerDatosProyecto(idProyecto) {
      this.datosProyecto = {};
      this.skeletonLoading = true;
      this.tableLoading = true;

      this.$store
        .dispatch(OBTENER_DATOS_SECCIONES_PROYECTO, {
          seccion: "PlazosProyecto",
          id: idProyecto
        })
        .then(res => {
          if (res.status === 200) {
            this.datosProyecto = res.data;

            //this.cuentaCondicionDesembolsoSeleccionada.id = this.datosItem.condicionesDesembolsos;

            this.datosProyecto.fechaSuscripcionPlazos
              ? (this.fechaSuscripcionPlazos = moment(
                  this.datosProyecto.fechaSuscripcionPlazos,
                  "YYYY-MM-DD"
                ).format("DD-MM-YYYY"))
              : (this.fechaSuscripcionPlazos = "");
            this.datosProyecto.fechaInicioPlazos
              ? (this.fechaInicioPlazos = moment(
                  this.datosProyecto.fechaInicioPlazos,
                  "YYYY-MM-DD"
                ).format("DD-MM-YYYY"))
              : (this.fechaInicioPlazos = "");
            this.datosProyecto.fechaAprobacionPlazos
              ? (this.fechaAprobacionPlazos = moment(
                  this.datosProyecto.fechaAprobacionPlazos,
                  "YYYY-MM-DD"
                ).format("DD-MM-YYYY"))
              : (this.fechaAprobacionPlazos = "");
            this.datosProyecto.fechaFinalizacionFaseEjecucion
              ? (this.fechaFaseEjecucionActual = moment(
                  this.datosProyecto.fechaFinalizacionFaseEjecucion,
                  "YYYY-MM-DD"
                ).format("DD-MM-YYYY"))
              : (this.fechaFinalizacionFaseEjecucion = "");
            this.datosProyecto.fechaFinalizacionPlazos
              ? (this.fechaFinalizacionActual = moment(
                  this.datosProyecto.fechaFinalizacionPlazos,
                  "YYYY-MM-DD"
                ).format("DD-MM-YYYY"))
              : (this.fechaFinalizacionPlazos = "");
            this.codigoProyecto = this.datosProyecto.codigo;
            if (this.datosProyecto.enmiendasFechas === 1) {
              this.fechaFaseEjecucionActual = this.datosProyecto.fechaFaseEjecucionNueva;
              this.fechaFinalizacionActual = this.datosProyecto.fechaFinalizacionNueva;
            }
            this.proyectoEncontrado = true;
            this.skeletonLoading = false;

            this.obtenerEnimendas(this.proyectoId);
            //this.skeletonLoading = false;

            //console.log(this.datosProyecto.condicionesDesembolsos)

            //console.log(this.cuentaCondicionDesembolsoSeleccionada.id)
            //this.habilitarCondicionesDesembolsos = true; condicionesDesembolsos
          } else {
            this.proyectoEncontrado = false;
            this.skeletonLoading = false;
            this.tableLoading = false;
          }
        })
        .catch(() => {
          this.datosProyecto = {};
          this.skeletonLoading = false;
        });
    },

    //Obtener las enmiendas registradas
    async obtenerEnimendas(proyectoId) {
      this.enmiendas = [];
      this.tableLoading = true;
      this.$store
        .dispatch(OBTENER_ENMIENDAS_FECHAS_CNS, {
          estadoId: 0,
          id: proyectoId,
          rol:
            this.currentRoles.includes("SCO$ADMINISTRADOR") ||
            this.currentRoles.includes("SCO$SUBADMINISTRADOR")
              ? "admin"
              : "inst"
        })
        .then(res => {
          if (res.status === 200) {
            this.enmiendas = this.$store.state.enmiendafechascns.enmiendasFechas;
          }
          this.tableLoading = false;
        })
        .catch(() => {
          this.enmiendas = [];
          this.tableLoading = false;
        });
    },

    modalNuevo() {
      this.obtenerFechasActuales(this.proyectoId);
      this.accion = 1;
      this.deshabilitarCampos = false;
      this.enmiendaRegistrada = false;
      this.enmiendaValidada = false;
      this.resetForm();
      this.dialogRegistro = true;
    },

    //Obtener los meses
    async obtenerFechasActuales(proyectoId) {
      this.datosFechasActuales = {};

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: `EnmiendaFechasCNS/UltimaActiva/${proyectoId}`
        })
        .then(res => {
          if (res.status === 200) {
            this.datosFechasActuales = res.data;
          }
        })
        .catch(() => {
          this.datosFechasActuales = {};
        });
    },

    onFileChange(e) {
      if (e) {
        //!?Validar que el tipo de archivo sea pdf
        if (e.type != "application/pdf" && e.type != "image/jpeg") {
          // Api call
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `El archivo que desea ingresar no es válido.`
          );
          //console.log("no es un pdf..")
          //this.$refs.file.reset();
          document.querySelector("#respaldoFile").value = "";
          this.$refs.respaldoFile.reset();
          return false;
        }
        //?Validar que el tamaó del archivo no exceda los 10Mb
        if (e.size / 1024 / 1024 > 10) {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `El tamaño del archivo excede el límite permitido (10Mb)`
          );
          // this.$refs.file.reset();
          document.querySelector("#respaldoFile").value = "";
          this.$refs.respaldoFile.reset();
          return false;
        }

        this.archivo = e;
      }
    },

    resetForm() {
      this.fechaFaseEjecucionNueva = "";
      this.fechaFinalizacionNueva = "";
      //document.querySelector("#docFile").value = "";
      //$('#docFile').val('');
      //this.$refs.docFile.reset();

      if (this.archivo && this.archivo.size) {
        this.archivoRespaldo = [];
        if (this.$refs.respaldoFile) {
          this.$refs.respaldoFile.reset();
        }
        //this.$refs['respaldoFile'][0].reset();
      }

      //this.archivo=[];

      this.datosItem = {
        proyectosCNSId: this.proyectoId,
        fechaFaseEjecucionNueva: "",
        pathDocumentoRespaldo: "",
        fechaFinalizacionNueva: "",
        observaciones: "",
        usuarioCreacion: "admin",
        estadosId: 4,
        fechaFaseEjecucionModificada: "",
        fechaFinalizacionModificada: ""
      };
    },

    // Confirmar y cargar archivo de respaldo
    async cargarArchivo() {
      const files = this.archivo;
      let path = "";
      this.btnConfirmacionLoading = true;
      // this.datosArchivo={};
      //Definir la ruta segun el tipo de archivo

      path = "\\cns\\documentos\\enmiendas\\";

      await this.$store
        .dispatch(CARGAR_ARCHIVO, { file: files, path: path })
        .then(res => {
          //console.logres
          if (res.status === 200) {
            this.archivoCargado = true;

            //Agregar el path en función del tipo de archivo que se cargó

            //this.datosItem.pathDocumentoRespaldo = res.pathArchivo;
            this.datosValidacion.pathDocumentoRespaldo = res.pathArchivo;

            //this.$refs.snackalert.SnackbarShow('success', 'Mensaje', res.mensaje);
            this.confirmarEmienda();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.mensaje
            );
            this.archivoCargado = false;
            this.btnConfirmacionLoading = false;
          }
          //this.btnRegistroLoading=false;
        })
        .catch(error => {
          this.$refs.snackalert.SnackbarShow("warning", "Mensaje", error);
          this.btnConfirmacionLoading = false;
        });
    },

    //Registrar una enmienda
    async registrarItem() {
      this.btnRegistroLoading = true;
      this.datosItem.proyectosCNSId = this.proyectoId;
      this.datosItem.fechaFaseEjecucionNueva = moment(
        this.fechaFaseEjecucionNueva,
        "DD-MM-YYYY"
      ).format("YYYY-MM-DD");
      this.datosItem.fechaFinalizacionNueva = moment(
        this.fechaFinalizacionNueva,
        "DD-MM-YYYY"
      ).format("YYYY-MM-DD");
      this.datosItem.fechaFaseEjecucionModificada = moment(
        this.fechaFaseEjecucionActual,
        "DD-MM-YYYY"
      ).format("YYYY-MM-DD");
      this.datosItem.fechaFinalizacionModificada = moment(
        this.fechaFinalizacionActual,
        "DD-MM-YYYY"
      ).format("YYYY-MM-DD");

      await this.$store
        .dispatch(REGISTRAR_ENMIENDA_FECHAS_CNS, { datos: this.datosItem })
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.datosItem.estadosId = 1;
            //this.enmiendaValidada = true;
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.enmiendaId = res.data;
            this.enmiendaRegistrada = true;
            this.accion = 2;
            //this.obtenerProyeccionesDesembolsos(this.id);
            //this.resetItemsDesembolsos();
            //this.datosMecanismoEjecucion.mecanismosEjecucionId = 0;
            this.obtenerDatosProyecto(this.proyectoId);
            //this.resetForm();
            //this.dialogRegistro = false;
            //this.obtenerEnimendas(this.proyectoId);
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroLoading = false;
        })
        .catch(error => {
          this.btnRegistroLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    async obtenerDatosItem(proyectoId) {
      this.dialogLoaderVisible = true;
      this.enmiendaId = proyectoId;
      this.dialogLoaderText = "Obteniendo información de la enmienda...";
      this.datosItem = {};

      this.$store
        .dispatch(OBTENER_ENMIENDA_FECHAS_CNS, { id: proyectoId })
        .then(res => {
          if (res.status === 200) {
            this.datosItem = this.$store.state.enmiendafechascns.enmiendaFechas;
            this.deshabilitarCampos = true;
            this.fechaFaseEjecucionNueva = this.datosItem.fechaFaseEjecucionNueva; // moment(this.datosItem.fechaFaseEjecucionNueva, "YYYY-MM-DD").format("DD/MM/YYYY");
            this.fechaFinalizacionNueva = this.datosItem.fechaFinalizacionNueva; //moment(this.datosItem.fechaFinalizacionNueva, "YYYY-MM-DD").format("DD/MM/YYYY");

            this.datosFechasActuales.fechaFaseEjecucion = this.datosItem.fechaFaseEjecucionModificada;
            this.datosFechasActuales.fechaFinalizacion = this.datosItem.fechaFinalizacionModificada;

            this.dialogRegistro = true;

            if (this.datosItem.estadosId === 4) {
              this.enmiendaValidada = true;
              this.enmiendaRegistrada = false;
            } else {
              this.enmiendaValidada = false;
              this.enmiendaRegistrada = true;
            }
            this.accion = 2;
          }
          this.dialogLoaderVisible = false;
        })
        .catch(() => {
          this.datosItem = {};
          this.dialogLoaderVisible = false;
        });
    },

    //!Descargar el archivo de respaldo
    descargarArchivo(path) {
      /* let newPath = path.replace(/\\/g, '/');
            //console.log(newPath);
            window.open("http://localhost:5236"+ newPath, "_blank"); */
      DownloadFile.download(path);
    },

    mostrarModalInactivar(id, nuevoEstadoId) {
      if (nuevoEstadoId === 2) {
        this.textoTituloCambioEstado = "Inactivar enmienda";
        this.textoCambioEstado =
          "¿Está seguro de inactivar la enmienda seleccionada?";
      }
      if (nuevoEstadoId === 1) {
        this.textoTituloCambioEstado = "Activar enmienda";
        this.textoCambioEstado =
          "¿Está seguro de activar nuevamente la enmienda seleccionada?";
      }
      this.idSeleccionado = id;
      this.estadoActualId = nuevoEstadoId;
      //Si el estado actual es confirmado, pasarlo a inactivo
      this.nuevoEstadoId = nuevoEstadoId;
      this.dialogActualizarEstado = true;
    },

    async actualizarEstadoEnmienda() {
      this.btnCambioEstadoLoading = true;
      await this.$store
        .dispatch(ACTUALIZAR_ESTADO_ENMIENDA_FECHA_CNS, {
          id: this.idSeleccionado,
          estadoId: this.nuevoEstadoId
        })
        .then(res => {
          //console.logres
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.dialogActualizarEstado = false;
            this.obtenerDatosProyecto(this.proyectoId);
            //this.obtenerEnimendas(this.proyectoId);
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Error!",
              res.message
            );
          }
          this.btnCambioEstadoLoading = false;
        })
        .catch(error => {
          //console.logerror
          this.dialogActualizarEstado = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            "Ha ocurrido un error al intentar realizar la acción especificada, por favor, póngase en contacto con un administrador del sistema. REF:" +
              error
          );
          this.btnCambioEstadoLoading = false;
        });
    },

    confirmarEmienda() {
      this.datosValidacion.id = this.enmiendaId;
      this.datosValidacion.observaciones = this.datosItem.observaciones;

      this.$store
        .dispatch(CONFIRMAR_ENMIENDA_FECHAS_CNS, this.datosValidacion)
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.enmiendaValidada = true;
            this.obtenerEnimendas(this.proyectoId);
            //this.obtenerSubdivisionesEnmiendaCP(this.enmiendaId);
            this.dialogRegistro = false;
            //this.resetItemsSubdivisionCP();
            // this.resetItems(2);
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnConfirmacionLoading = false;
          this.btnValidarEnmienda = false;
        })
        .catch(() => {
          this.btnValidarEnmienda = false;
          this.btnConfirmacionLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
        });
    },
  },

  created() {
    this.parameters = this.$route.params;
    this.proyectoId = this.$CryptoJS.AES.decrypt(
      this.parameters.proyectoId,
      "KEYADMINCNS2022"
    ).toString(this.CryptoJS.enc.Utf8);

    //this.obtenerSubDivisiones();
    this.obtenerDatosProyecto(this.proyectoId);

    //Generar la fecha

    var today = new Date();

    var date =
      +today.getDate() +
      "/" +
      String(today.getMonth() + 1).padStart(2, "0") +
      "/" +
      today.getFullYear();
    this.datosItem.fechaEnmienda = date;
  },

  computed: {
    currentRoles: {
      get() {
        return this.$store.state.auth.roles;
      }
    },
    headers() {
      return [
        {
          text: "Número",
          align: "start",
          sortable: false,
          value: "codigo"
        },
        {
          text: "Código de enmienda",
          align: "start",
          sortable: true,
          value: "numeroEnmienda"
        },
        {
          text: "Fecha enmienda",
          align: "start",
          sortable: true,
          value: "fechaCreacion"
        },
        {
          text: "Fecha fase de ejecución",
          align: "start",
          sortable: true,
          value: "fechaFaseEjecucionNueva"
        },
        {
          text: "Fecha finalización",
          align: "start",
          sortable: true,
          value: "fechaFinalizacionNueva"
        },
        {
          text: "Observaciones",
          align: "start",
          sortable: true,
          value: "observaciones"
        },
        {
          text: "Estado",
          align: "start",
          sortable: true,
          value: "estadosId",
          filter: this.filtroEstado
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: ""
        }
      ];
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Inicio", route: "dashboard" },
      { title: "Proyectos" },
      { title: "CNS", route: "proyectos-cns" },
      { title: "Enmiendas fechas" }
    ]);

    // var ciphertext = this.$CryptoJS.AES.encrypt("2", 'KEYADMINCNS2022');
  }
};
</script>
